export default {

    /** Auth **/
    async forgotPassword(email) {
        return window.axios.post('/api/forgot-password', {email})
    },
    async resetPassword(params) {
        return window.axios.post('/api/reset-password', params)
    },

    //Markers
    async getMarkers(params) {
        return window.axios.post('/api/markers', params)
    },

    //Leads
    async storeLeads(params) {
        return window.axios.post('/api/leads', params)
    },

    async favoriteProject(slug){
        return window.axios.post(`/api/projects/slug/${slug}/favorite`)
    }
}
