<template>
    <v-row>
        <v-col cols="rounded-0 pa-0 white-space-nowrap">
            <v-dialog
                transition="dialog-top-transition"
                :max-height="$vuetify.breakpoint.width > 900 ? 500 : 620"
                :max-width="$vuetify.breakpoint.width > 900 ? 820 : 395"
                class="p-relative overflow-hidden rounded-0">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed :id="btn_location" plain
                           v-bind="attrs"
                           v-on="on"
                           @click.prevent
                    >{{ $t('menu.contact_us') }}</v-btn>
                </template>
                <template v-slot:default="dialog">
                    <v-card class="d-block d-md-flex p-relative overflow-hidden" rounded="0">
                        <v-img src="/img/waiting_call_dialog.jpg"
                               :max-height="$vuetify.breakpoint.width > 900 ? '100%' : 133"
                               :max-width="$vuetify.breakpoint.width > 900 ? 315 : 395"
                               class="border-radius0"></v-img>
                        <div class="dialog-content">
                            <v-btn text @click="dialog.value = false" id="close" max-height="30" max-width="30">
                                &times;
                            </v-btn>
                            <div>
                                <v-img
                                    :alt="appName + 'logo'"
                                    class="shrink mb-8"
                                    contain
                                    src="/img/logo.png"
                                    transition="scale-transition"
                                    width="180"/>
                                <p class="dialog-p">Хотите мы перезвоним и расскажем о дополнительных
                                    <span class="text-bold">скидках и условиях?</span>
                                </p>
                                <v-form ref="form" id="form__waitingCallDialog">
                                    <v-text-field :error-messages="errors.full_name" class="text-input mb-8 transparent"
                                                  :label="$t('headers.form_your_name')"
                                                  :rules="rules_full_name"
                                                  type="text" solo
                                                  height="47"
                                                  max-height="47"
                                                  width="360"
                                                  @input="btn_active()"
                                                  v-model="full_name"/>
                                    <v-text-field :error-messages="errors.email" class="text-input mb-8 transparent"
                                                  :label="$t('headers.form_your_email')"
                                                  :rules="rules_email"
                                                  type="email" solo
                                                  height="47"
                                                  max-height="47"
                                                  width="360"
                                                  @input="btn_active()"
                                                  v-model="email"/>
                                    <v-text-field :error-messages="errors.phone" class="text-input mb-8 transparent"
                                                  :label="$t('headers.form_your_phone')"
                                                  :rules="rules_phone_number"
                                                  type="number" solo
                                                  height="47"
                                                  max-height="47"
                                                  width="360"
                                                  @input="btn_active()"
                                                  v-model="phone"/>
                                    <v-btn class="btn-submit" @click="submit" :disabled="!btn_disabled">Жду звонка!
                                    </v-btn>
                                    <v-btn v-if="show_btn_time_call" @click="showTimeCall" depressed class="transparent time_to_call mt-2 pl-0 mb-2">
                                        <v-icon style="color: rgba(63, 68, 196, 0.57) !important;" class="mr-2">
                                            mdi-clock-time-three-outline
                                        </v-icon>
                                        <span class="border-bottom">Выбрать удобное время звонка</span>
                                    </v-btn >
                                    <v-row v-if="show_time_call" style="width: 60%; height: 80px">
                                        <v-col class="d-flex justify-lg-end justify-space-between mt-3 align-baseline">
                                            <div>С</div>
                                            <v-select :label="time_from"
                                                      append-icon="mdi-chevron-down"
                                                      solo
                                                      v-model="time_from"
                                                      :items="time_to_call">
                                            </v-select>
                                            <div>До</div>
                                            <v-select :label="time_to"
                                                      append-icon="mdi-chevron-down"
                                                      solo
                                                      v-model="time_to"
                                                      :items="time_to_call">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-checkbox
                                        @click="check_privacy_policy = !check_privacy_policy"
                                        class="mt-1"
                                        color="violet"
                                        >
                                        <template v-slot:label>
                                            <div :class="returnColorPersonalData">Я согласен на обработку персональных данных</div>
                                        </template>
                                    </v-checkbox>
                                </v-form>
                            </div>
                        </div>
                    </v-card>
                </template>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
import Api from "@/api/Api";

export default {
    name: "WaitingCallDialog",
    props: ['btn_location'],
    data: function () {
        return {
            dialog: false,
            text: 'Are you sure?',
            full_name: '',
            email: '',
            phone: '',
            check_privacy_policy: false,
            color_privacy_policy: true,
            rules_full_name: [
                v => !!v || 'Required.',
                v => (v && v.length >= 3) || 'Min 3 characters',
            ],
            rules_email: [
                v => !!v || 'Required.',
                v => (v && v.length >= 6) || 'Min 6 characters',
                v => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(v) || 'Invalid e-mail.'
                },
            ],
            rules_phone_number: [
                v => !!v || 'Required.',
                v => (v && v.length >= 10) || 'Min 10 characters',
            ],
            errors: {},
            btn_disabled: false,
            show_btn_time_call: true,
            show_time_call: false,
            time_from: null,
            time_to: null,
            time_to_call: [
                '06:00', '07:00', '08:00',
                '09:00', '10:00', '11:00',
                '12:00', '13:00', '14:00',
                '15:00', '16:00', '17:00',
                '18:00', '19:00', '20:00',
                '21:00', '22:00',
            ]
        }
    },
    methods: {
        async submit() {
            if (this.check_privacy_policy){
            const {full_name, email, phone, time_from, time_to} = this;
                await Api.storeLeads({
                    name: full_name,
                    email: email,
                    phone: phone,
                    time_call: `C ${time_from} До ${time_to}`
                })
                this.color_privacy_policy = true
            }
            else {
                this.color_privacy_policy = false
            }
        },
        valid() {
            return this.$refs.form.validate()
        },
        btn_active() {
            this.btn_disabled = this.valid()
        },
        showTimeCall(){
            this.show_time_call = !this.show_time_call
            this.show_btn_time_call = !this.show_btn_time_call
            this.time_from = '12:00'
            this.time_to = '13:00'
        }
    },
    computed: {
        appName() {
            return process.env.VUE_APP_APP_NAME ? process.env.VUE_APP_APP_NAME : "GETHOME"
        },
        returnColorPersonalData: function () {
            return this.color_privacy_policy ? 'text_show_message_blue' : 'text_show_message_red'
        },
    },
    mounted() {}
}
</script>

<style lang="scss" scoped>

#developer {
    background-color: white;
    font-size: 16px;
    color: var(--main_color-base);
    border: 2px solid var(--main_color-base);
    border-radius: 0;
    text-transform: none !important;
    letter-spacing: 0;
    font-weight: bold;
    min-height: 49px;
    width: 210px;
    margin-bottom: 16px;
}

#sale-card {
    background-color: white;
    color: var(--main_color-base);
    border: 1px solid var(--main_color-base);
    border-radius: 0;
    text-transform: none !important;
    letter-spacing: 0;
    font-size: 18px !important;
    font-weight: 100;
    min-height: 45px;
    align-self: stretch;
    width: 100%;
}

#header {

    white-space: nowrap;
    border: 1px solid var(--main_color-base) !important;
    border-radius: 0;
    color: var(--main_color-base);

    @media all and (max-width: 900px) {
        border: none !important;
        min-width: 36px;
        padding: 0;
        overflow: hidden;
    }
}

#footer {
    background: transparent;
    color: white !important;
    white-space: nowrap;
}

.max-height500 {
    max-height: 500px;
}

#close {
    max-width: 30px !important;
    max-height: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
    width: 30px !important;
    height: 30px !important;
    position: absolute;
    top: 5px;
    right: 10px;
    font-weight: bold;
    font-size: 24px;
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 50%;
}

.border-radius0 {
    border-radius: 0 !important;
}

.dialog-content {
    margin: 20px 40px;

    @media all and (max-width: 900px) {
        margin: 10px 20px;
    }

    .dialog-p {
        max-width: 370px;
        font-style: normal;
        font-weight: normal;
        font-size: 19px;
        line-height: 23px;
        color: #000000;

        .text-bold {
            font-weight: bold;
        }
    }
}

#form__waitingCallDialog {
    max-width: 100%;

    .text-input {
        border: 1px solid var(--main_color-base) !important;
        border-radius: 0;
        position: relative !important;
        max-height: 47px !important;
        height: 47px !important;
        width: 360px !important;

        .v-input__control {
            .v-input__slot {
                background: transparent !important;
            }
        }

        .v-text-field__details {
            position: absolute !important;
            bottom: -28px !important;
            background: transparent !important;
        }
    }

    .btn-submit {
        background: var(--main_color-base) !important;
        border: 1px solid var(--main_color-base) !important;
        box-sizing: border-box !important;
        border-radius: 0 !important;
        width: 360px !important;
        max-width: 100%;
        height: 47px !important;
        color: white !important;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        text-transform: none;
    }
}

.time_to_call {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: rgba(63, 68, 196, 0.57) !important;
    text-transform: none;
    max-width: 100%;
}

.border-bottom {
    border-bottom: dashed;
    border-bottom-width: 1px;
    color: rgba(63, 68, 196, 0.57) !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.text_show_message_blue
{
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 17px !important;
    color: rgba(63, 68, 196, 0.5) !important;
    text-transform: none !important;
    display: inline-block !important;
    max-width: 100%;
}

.text_show_message_red
{
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 17px !important;
    color: red !important;
    text-transform: none !important;
    display: inline-block !important;
    max-width: 100%;
}
.colorModel {
    color: blue;
}

.white-space-nowrap {
    white-space: nowrap;
}
</style>
